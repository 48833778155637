<template>
  <div class="root-block">
    <div
      v-if="$viewport.isGreaterOrEquals('md')"
      class="block-wrapper"
    >
      <div class="office-card-item">
        <OfficeCard
          v-for="office in offices"
          :key="`form-with-desc-offices-${office?.id}`"
          :office="office"
        />
      </div>
      <div class="form-item">
        <SingleForm ym-goal="ost_zayavka_futer_kv" />

        <div
          v-if="imageId"
          class="img adaptive-img"
        >
          <NuxtImg
            class="link-absolute"
            loading="lazy"
            placeholder
            fit="crop"
            width="516"
            height="328"
            alt="img"
            :src="String(imageId)"
          />
        </div>
      </div>
    </div>
    <div
      v-else
      class="block-mobile"
    >
      <MobileFormWithOffice
        v-for="office in offices"
        :key="`form-with-offices-${office?.id}`"
        :office="office"
      />
      <SingleForm ym-goal="ost_zayavka_futer_kv" />
    </div>
  </div>
</template>

<script setup lang="ts">
import OfficeCard from '~/components/form-offices-block/OfficeCard.vue';
import SingleForm from '~/components/form-offices-block/SingleForm.vue';

import MobileFormWithOffice from '~/components/form-offices-block/MobileFormWithOffice.vue';
import type { SaleOfficeType } from '~/types/SaleOfficeBlock';

const props = defineProps<{
	imageId: number;
	offices: SaleOfficeType[];
}>();

const $viewport = useViewport();

</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.root-block {
	&:deep(.form-block-root) {
		& .form-title {
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 130%; /* 20.8px */
			letter-spacing: 0.32px;
			text-transform: uppercase;
			margin-bottom: 32px;
		}

		& .form-inputs {
			flex-direction: column;
			gap: 28px;

			& > .input-wrapper > input {
				font-size: 16px;
				font-style: normal;
				font-weight: 600;
				line-height: 110%; /* 16.5px */
				letter-spacing: -0.3px;
			}
		}

		& .form-btn {
			width: 100%;
		}

		& .checkbox-wrapper {
			margin-top: 16px;
			justify-content: center;

			& > label {
				font-size: 11px;
				font-style: normal;
				font-weight: 600;
				line-height: 109.5%;
				letter-spacing: -0.22px;
			}
		}
	}
}

.block-wrapper {
	display: flex;
	gap: 54px;
}

.office-card-item {
	width: 50%;
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.form-item {
	width: 40%;

	& .img {
		margin-top: 60px;
		border-radius: 22px;
		overflow: hidden;
		max-height: 328px;
		object-fit: cover;

		& > img {
			width: 100%;
			height: 100%;
		}
	}
}

@include media('md') {
	.block-wrapper {
		flex-direction: column;
		gap: 40px;
	}

	.office-card-item {
		width: 100%;
	}

	.form-item {
		width: 100%;
	}
}
</style>
