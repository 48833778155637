<template>
  <section
    class="sale-office-root"
    :class="$customSectionClasses(props.cfgs)"
  >
    <div class="container">
      <div class="h2 title">{{ values.title }}</div>
      <FormWithOffices
        :image-id="Number(values.image)"
        :offices="extras.stores"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import type { IBlockDefaultProps } from '#sitis/internal/models/common';
import type { SectionTypes } from '~/plugins/customSectionClasses';
import FormWithOffices from '~/components/form-offices-block/FormWithOffices.vue';
import type { SaleOfficeType } from '~/types/SaleOfficeBlock';

type SaleOfficeBlockType = Omit<IBlockDefaultProps, 'values' | 'cfgs'|'extras'> & {
	values: {
		title: string;
		image: number;
	};
	extras:{ stores:SaleOfficeType[]; };
	cfgs: SectionTypes;
}

const { $customSectionClasses } = useNuxtApp();
const props = defineProps<SaleOfficeBlockType>();
</script>

<style scoped lang="scss">
@import "@/assets/scss/media";

.sale-office-root {
	background: var(--primary-10);
	padding: 75px 0;

	.title {
		font-size: 36px;
		font-style: normal;
		font-weight: 700;
		line-height: 130%; /* 46.8px */
		letter-spacing: -1.08px;
		margin-bottom: 48px;
	}
}

@include media('lg') {
	.sale-office-root {
		border-radius: 20px;
		padding: 24px 16px;
		margin-bottom: -14px;
		position: relative;
		z-index: 11;

		& .title{
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 130%; /* 31.2px */
			letter-spacing: -0.48px;
			margin-bottom: 48px;
			position: relative;
			padding-left: 15px;

			&::before{
				content: '';
				position: absolute;
				height: 100%;
				width: 6px;
				background:var(--secondary);
				top: 0;
				left: 0;
			}
		}

		& > .container {
			padding: 0;
		}
	}

}
</style>
